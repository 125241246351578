import { DEFAULT_CHAIN_ID } from "config";
import { usePoolFromName, useTokenPrice } from "state/hooks";
import { getBalanceNumber } from "utils/formatBalance";
import "./dividends.scss";

function Dividend({ poolName, name, start, end }) {
  const pool = usePoolFromName(poolName);
  const totalStakedInNum = getBalanceNumber(
    pool.totalSupply,
    pool.stakeTokenDecimal
  );

  const tokenPrice = useTokenPrice(
    pool.stakeTokenSymbol,
    false,
    DEFAULT_CHAIN_ID
  );
  const totalStakedInUsd = totalStakedInNum * tokenPrice;

  const totalStaked = pool.totalSupply
    ? `${getBalanceNumber(
        pool.totalSupply,
        pool.stakeTokenDecimal
      ).toLocaleString(undefined, { maximumFractionDigits: 2 })}`
    : "-";
  const totalStakedInUsdFormatted = totalStakedInUsd
    ? `${totalStakedInUsd.toLocaleString(undefined, {
        maximumSignificantDigits: 3,
      })}`
    : `-`;

  return (
    <div className="dividend">
      <h2>{name}</h2>
      <h3>
        {start} - {end}
      </h3>
      <h3>
        Total Staked: {totalStaked} FIBO ({totalStakedInUsdFormatted} USD)
      </h3>
      <div className="buttons">
        {/* <button>+ Get</button>
                <button disabled>- Repay</button> */}
        <button className="smartcontract">Vote</button>
      </div>
    </div>
  );
}

export default Dividend;
