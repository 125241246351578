import { PoolConfig } from "./types";

const pools: PoolConfig[] = [
  {
    name: "FIBO-1",
    isNativePool: false,
    isLpPool: false,
    isSpecialPool: false,
    active: true,
    address: {
      56: "",
      97: "",
      42161: "",
      137: "0x4c0739Af8CcD564622D6eB9070795d6c9c907254",
    },
    stakeToken: {
      56: "",
      97: "",
      42161: "",
      137: "0x2b3B16826719bF0B494c8ddebaA5E882093eE37e",
    },
    stakeTokenSymbol: "FIBO",
    stakeTokenDecimal: 18,
    rewardToken: {
      56: "",
      97: "",
      42161: "",
      137: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    },
    rewardTokenSymbol: "USDC",
    rewardTokenDecimal: 6,
    logo: "images/farms/fibo.png",
    lockingDuration: 604800,
  },
  {
    name: "FIBO-2",
    isNativePool: false,
    isLpPool: false,
    isSpecialPool: false,
    active: false,
    address: {
      56: "",
      97: "",
      42161: "",
      137: "0xA8D3B2c3581D17fBda7fb58b28d7465143f73B21",
    },
    stakeToken: {
      56: "",
      97: "",
      42161: "",
      137: "0x2b3B16826719bF0B494c8ddebaA5E882093eE37e",
    },
    stakeTokenSymbol: "FIBO",
    stakeTokenDecimal: 18,
    rewardToken: {
      56: "",
      97: "",
      42161: "",
      137: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    },
    rewardTokenSymbol: "USDC",
    rewardTokenDecimal: 6,
    logo: "images/farms/fibo.png",
    lockingDuration: 7776000,
  },
  {
    name: "FIBO-3",
    isNativePool: false,
    isLpPool: false,
    isSpecialPool: false,
    active: false,
    address: {
      56: "",
      97: "",
      42161: "",
      137: "0x4812c3EeA484b659f53Bc6d899536EC25B4b64c2",
    },
    stakeToken: {
      56: "",
      97: "",
      42161: "",
      137: "0x2b3B16826719bF0B494c8ddebaA5E882093eE37e",
    },
    stakeTokenSymbol: "FIBO",
    stakeTokenDecimal: 18,
    rewardToken: {
      56: "",
      97: "",
      42161: "",
      137: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    },
    rewardTokenSymbol: "USDC",
    rewardTokenDecimal: 6,
    logo: "images/farms/fibo.png",
    lockingDuration: 7776000,
  },
];

export default pools;
