/* eslint-disable jsx-a11y/anchor-is-valid */
import "./footer.scss";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="leftSection">
          <img src="/logoWithName.svg" alt="logo" />
          <p className="description">
            FibPal is world's first DeFi multi-cross-chain NFT payment system that
            can be used to purchase NFTs using thousands of Tokens across all of
            our supported chains.
          </p>
          <div className="socialMediaLinks">
            <a href="https://www.facebook.com/FibSWAPDEx/">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.11 0.758774C8.56 0.758774 0 9.31877 0 19.8688C0 29.4088 6.99 37.3088 16.12 38.7488V25.3988H11.27V19.8788H16.12V15.6688C16.12 10.8788 18.97 8.23877 23.34 8.23877C25.43 8.23877 27.62 8.60878 27.62 8.60878V13.3088H25.21C22.84 13.3088 22.1 14.7788 22.1 16.2888V19.8688H27.4L26.55 25.3888H22.1V38.7388C31.24 37.3088 38.22 29.3988 38.22 19.8588C38.22 9.30878 29.66 0.748779 19.11 0.748779V0.758774Z"
                  fill="white"
                />
              </svg>
            </a>
            <a href="https://t.me/FibSwapOfficial">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.41 0.758789C8.86005 0.758789 0.300049 9.31879 0.300049 19.8688C0.300049 30.4188 8.86005 38.9788 19.41 38.9788C29.96 38.9788 38.52 30.4188 38.52 19.8688C38.52 9.31879 29.96 0.758789 19.41 0.758789ZM28.7301 12.9088C28.6201 14.6688 25.37 27.7588 25.37 27.7588C25.37 27.7588 25.18 28.5288 24.48 28.5488C24.23 28.5488 23.92 28.5088 23.55 28.1888C22.82 27.5588 21.11 26.3488 19.53 25.2588C19.05 25.6588 18.1 26.4888 17.11 27.4688C15.71 28.8688 15.89 27.4688 15.89 27.4688L16.3201 22.8988H16.33L16.3801 22.8188C16.3801 22.8188 24.62 15.4888 24.84 14.7288C24.86 14.6688 24.7901 14.6388 24.7101 14.6688C24.1601 14.8688 14.6701 20.8588 13.6301 21.5188C13.5701 21.5588 13.4 21.5288 13.4 21.5288L8.80005 20.0288C8.80005 20.0288 8.25005 19.8088 8.43005 19.2988C8.47005 19.1988 8.54004 19.1088 8.76004 18.9488C9.78004 18.2388 27.64 11.8188 27.64 11.8188C27.64 11.8188 28.14 11.6488 28.44 11.7588C28.58 11.8088 28.66 11.8688 28.74 12.0788C28.77 12.1588 28.78 12.3188 28.78 12.4888C28.78 12.6088 28.76 12.7188 28.75 12.8888L28.7301 12.9088Z"
                  fill="white"
                />
              </svg>
            </a>
            <a href="https://youtube.com/c/FibSwapDExOfficial">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.1602 23.6888L23.7802 19.8688L17.1602 16.0388V23.6888Z"
                  fill="white"
                />
                <path
                  d="M19.7101 0.758789C9.16008 0.758789 0.600098 9.31879 0.600098 19.8688C0.600098 30.4188 9.16008 38.9788 19.7101 38.9788C30.2601 38.9788 38.8201 30.4188 38.8201 19.8688C38.8201 9.31879 30.2601 0.758789 19.7101 0.758789ZM31.9201 25.9988C31.6301 27.0988 30.7601 27.9588 29.6701 28.2488C27.6801 28.7788 19.7201 28.7788 19.7201 28.7788C19.7201 28.7788 11.7501 28.7788 9.77008 28.2488C8.67008 27.9588 7.81008 27.0888 7.52008 25.9988C6.99008 24.0088 6.99008 19.8688 6.99008 19.8688C6.99008 19.8688 6.99008 15.7188 7.52008 13.7388C7.81008 12.6388 8.68008 11.7788 9.77008 11.4888C11.7601 10.9588 19.7201 10.9588 19.7201 10.9588C19.7201 10.9588 27.6901 10.9588 29.6701 11.4888C30.7701 11.7788 31.6301 12.6488 31.9201 13.7388C32.4501 15.7288 32.4501 19.8688 32.4501 19.8688C32.4501 19.8688 32.4501 24.0188 31.9201 25.9988Z"
                  fill="white"
                />
              </svg>
            </a>
            <a href="https://www.instagram.com/fibswapdex/?igshid=YmMyMTA2M2Y%3D">
              <svg
                width="40"
                height="39"
                viewBox="0 0 40 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.01 23.8788C22.2247 23.8788 24.02 22.0834 24.02 19.8688C24.02 17.6541 22.2247 15.8588 20.01 15.8588C17.7953 15.8588 16 17.6541 16 19.8688C16 22.0834 17.7953 23.8788 20.01 23.8788Z"
                  fill="white"
                />
                <path
                  d="M29.3899 12.7688C29.1699 12.2088 28.9099 11.8088 28.4899 11.3888C28.0699 10.9688 27.6699 10.7088 27.1099 10.4888C26.6899 10.3288 26.0499 10.1288 24.8799 10.0788C23.6099 10.0188 23.2299 10.0088 20.0199 10.0088C16.8099 10.0088 16.4299 10.0188 15.1599 10.0788C13.9899 10.1288 13.3499 10.3288 12.9299 10.4888C12.3699 10.7088 11.9699 10.9688 11.5499 11.3888C11.1299 11.8088 10.8699 12.2088 10.6499 12.7688C10.4899 13.1888 10.2899 13.8288 10.2399 14.9988C10.1799 16.2688 10.1699 16.6488 10.1699 19.8588C10.1699 23.0688 10.1799 23.4488 10.2399 24.7188C10.2899 25.8888 10.4899 26.5288 10.6499 26.9488C10.8699 27.5088 11.1299 27.9088 11.5499 28.3288C11.9699 28.7488 12.3699 29.0088 12.9299 29.2288C13.3499 29.3888 13.9899 29.5888 15.1599 29.6388C16.4299 29.6988 16.8099 29.7088 20.0199 29.7088C23.2299 29.7088 23.6099 29.6988 24.8799 29.6388C26.0499 29.5888 26.6899 29.3888 27.1099 29.2288C27.6699 29.0088 28.0699 28.7488 28.4899 28.3288C28.9099 27.9088 29.1699 27.5088 29.3899 26.9488C29.5499 26.5288 29.7499 25.8888 29.7999 24.7188C29.8599 23.4488 29.8699 23.0688 29.8699 19.8588C29.8699 16.6488 29.8599 16.2688 29.7999 14.9988C29.7499 13.8288 29.5499 13.1888 29.3899 12.7688ZM20.0099 26.0388C16.5999 26.0388 13.8299 23.2688 13.8299 19.8588C13.8299 16.4488 16.5999 13.6788 20.0099 13.6788C23.4199 13.6788 26.1899 16.4488 26.1899 19.8588C26.1899 23.2688 23.4199 26.0388 20.0099 26.0388ZM26.4299 14.8788C25.6299 14.8788 24.9899 14.2288 24.9899 13.4388C24.9899 12.6488 25.6399 11.9988 26.4299 11.9988C27.2199 11.9988 27.8699 12.6488 27.8699 13.4388C27.8699 14.2288 27.2199 14.8788 26.4299 14.8788Z"
                  fill="white"
                />
                <path
                  d="M20.0099 0.758789C9.45992 0.758789 0.899902 9.31879 0.899902 19.8688C0.899902 30.4188 9.45992 38.9788 20.0099 38.9788C30.5599 38.9788 39.1199 30.4188 39.1199 19.8688C39.1199 9.31879 30.5599 0.758789 20.0099 0.758789ZM31.9699 24.8288C31.9099 26.1088 31.7099 26.9888 31.4099 27.7488C31.0999 28.5388 30.6899 29.2088 30.0199 29.8788C29.3499 30.5488 28.6799 30.9588 27.8899 31.2688C27.1199 31.5688 26.2499 31.7688 24.9699 31.8288C23.6899 31.8888 23.2799 31.8988 20.0099 31.8988C16.7399 31.8988 16.3299 31.8888 15.0499 31.8288C13.7699 31.7688 12.8899 31.5688 12.1299 31.2688C11.3399 30.9588 10.6699 30.5488 9.99991 29.8788C9.32991 29.2088 8.91992 28.5388 8.60992 27.7488C8.30992 26.9788 8.1099 26.1088 8.0499 24.8288C7.9899 23.5488 7.97992 23.1388 7.97992 19.8688C7.97992 16.5988 7.9899 16.1888 8.0499 14.9088C8.1099 13.6288 8.30992 12.7488 8.60992 11.9888C8.91992 11.1988 9.32991 10.5288 9.99991 9.8588C10.6699 9.1888 11.3399 8.7788 12.1299 8.4688C12.8999 8.1688 13.7699 7.96878 15.0499 7.90878C16.3299 7.84878 16.7399 7.83879 20.0099 7.83879C23.2799 7.83879 23.6899 7.84878 24.9699 7.90878C26.2499 7.96878 27.1299 8.1688 27.8899 8.4688C28.6799 8.7788 29.3499 9.1888 30.0199 9.8588C30.6899 10.5288 31.0999 11.1988 31.4099 11.9888C31.7099 12.7588 31.9099 13.6288 31.9699 14.9088C32.0299 16.1888 32.0399 16.5988 32.0399 19.8688C32.0399 23.1388 32.0299 23.5488 31.9699 24.8288Z"
                  fill="white"
                />
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/fibswapdex/">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.3099 0.758789C8.75994 0.758789 0.199951 9.31879 0.199951 19.8688C0.199951 30.4188 8.75994 38.9788 19.3099 38.9788C29.8599 38.9788 38.42 30.4188 38.42 19.8688C38.42 9.31879 29.8599 0.758789 19.3099 0.758789ZM12.9999 30.3088C11.6399 30.2988 10.28 30.2988 8.91995 30.3088C8.69995 30.3088 8.63995 30.2588 8.63995 30.0288C8.63995 25.1888 8.63995 20.3388 8.63995 15.4988C8.63995 15.2988 8.68993 15.2288 8.89993 15.2288C10.2799 15.2288 11.6599 15.2388 13.0399 15.2288C13.2999 15.2288 13.3399 15.3288 13.3399 15.5588C13.3399 17.9688 13.3399 20.3788 13.3399 22.7988C13.3399 25.2188 13.3399 27.5888 13.3399 29.9788C13.3399 30.2488 13.2699 30.3288 12.9999 30.3188V30.3088ZM10.9899 13.1488C9.50993 13.1488 8.27994 11.9288 8.27994 10.4488C8.27994 8.94879 9.49994 7.71879 10.9999 7.72879C12.4899 7.72879 13.7099 8.94879 13.7099 10.4288C13.7099 11.9288 12.4899 13.1588 10.9899 13.1588V13.1488ZM30.92 29.9988C30.92 30.2488 30.85 30.3088 30.61 30.3088C29.25 30.2988 27.8899 30.2988 26.5299 30.3088C26.2899 30.3088 26.2399 30.2388 26.2399 30.0088C26.2399 27.4988 26.2399 24.9888 26.2399 22.4688C26.2399 21.8388 26.1999 21.2088 26.0199 20.5988C25.6899 19.4688 24.8899 18.8988 23.7099 18.9588C22.0999 19.0488 21.2599 19.8388 21.0499 21.4788C20.9999 21.8688 20.9799 22.2588 20.9799 22.6588C20.9799 25.0988 20.9799 27.5488 20.9799 29.9888C20.9799 30.2288 20.92 30.3088 20.67 30.2988C19.3 30.2888 17.9299 30.2888 16.5599 30.2988C16.3399 30.2988 16.2799 30.2388 16.2799 30.0188C16.2799 25.1788 16.2799 20.3488 16.2799 15.5088C16.2799 15.2688 16.3599 15.2188 16.5799 15.2188C17.8799 15.2188 19.18 15.2288 20.4799 15.2188C20.7199 15.2188 20.7799 15.2988 20.7799 15.5188C20.7699 16.0988 20.7799 16.6788 20.7799 17.2488C21.0899 16.8988 21.35 16.5388 21.67 16.2388C22.66 15.3088 23.83 14.8288 25.2 14.8488C25.95 14.8488 26.7 14.9088 27.42 15.1188C29.08 15.5988 30.0399 16.7388 30.4999 18.3588C30.8399 19.5788 30.9099 20.8188 30.9099 22.0688C30.9099 24.7088 30.9099 27.3488 30.9099 29.9888L30.92 29.9988Z"
                  fill="white"
                />
              </svg>
            </a>
            <a href="https://twitter.com/FibonacciDEx">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.61 0.758789C9.06002 0.758789 0.5 9.31879 0.5 19.8688C0.5 30.4188 9.06002 38.9788 19.61 38.9788C30.16 38.9788 38.72 30.4188 38.72 19.8688C38.72 9.31879 30.16 0.758789 19.61 0.758789ZM30.17 15.7788C30.18 15.9988 30.18 16.2188 30.18 16.4388C30.18 23.1388 25.08 30.8788 15.74 30.8788C12.87 30.8788 10.21 30.0388 7.95999 28.5988C8.35999 28.6488 8.76001 28.6688 9.17001 28.6688C11.55 28.6688 13.74 27.8588 15.47 26.4988C13.25 26.4588 11.38 24.9888 10.73 22.9788C11.04 23.0388 11.36 23.0688 11.68 23.0688C12.14 23.0688 12.59 23.0088 13.02 22.8888C10.7 22.4188 8.95001 20.3688 8.95001 17.9188C8.95001 17.8988 8.95001 17.8788 8.95001 17.8588C9.63001 18.2388 10.42 18.4688 11.25 18.4888C9.89 17.5788 8.98999 16.0288 8.98999 14.2688C8.98999 13.3388 9.23999 12.4688 9.67999 11.7188C12.18 14.7888 15.92 16.8088 20.14 17.0188C20.05 16.6488 20.01 16.2588 20.01 15.8588C20.01 13.0588 22.28 10.7888 25.08 10.7888C26.54 10.7888 27.86 11.4088 28.78 12.3888C29.94 12.1588 31.02 11.7388 32 11.1588C31.62 12.3388 30.82 13.3388 29.77 13.9688C30.8 13.8488 31.77 13.5788 32.68 13.1688C32 14.1888 31.14 15.0788 30.15 15.7988L30.17 15.7788Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>

        <div className="rightSection">
          <div className="section">
            <h6 className="heading">Questions?</h6>
            <div className="list">
              <a href="mailto:support@fibswap.io">
                <h6>Contact Us</h6>
              </a>
              <a href="https://fibswap.io/NewEra2.7.pdf">
                <h6>TOKENOMICS</h6>
              </a>
              <a href="https://t.me/fibswaptechsupport">
                <h6>
                  Live Tech Support{" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5302 0.0500031C15.4602 0.0200031 15.3802 0.0100098 15.3102 0.0100098H2.62025C2.30025 0.0100098 2.04018 0.270012 2.04018 0.590012C2.04018 0.910012 2.30025 1.17 2.62025 1.17H13.9202L1.0602 14.03C0.830195 14.26 0.830195 14.62 1.0602 14.85C1.1702 14.96 1.32023 15.02 1.47023 15.02C1.62023 15.02 1.77014 14.96 1.88014 14.85L14.7402 1.99001V13.29C14.7402 13.61 15.0002 13.87 15.3202 13.87C15.6402 13.87 15.9002 13.61 15.9002 13.29V0.600006C15.9002 0.520006 15.8802 0.450005 15.8602 0.380005C15.8002 0.240005 15.6902 0.130007 15.5502 0.0700073L15.5302 0.0500031Z"
                      fill="#FF007A"
                    />
                  </svg>
                </h6>
              </a>
              <a href="https://www.certik.com/projects/fibswap">
                <h6>CERTIK AUDIT</h6>
              </a>
              
              <a href="https://www.youtube.com/watch?v=XMLOcwiwjRE">
                <h6>
                  How to Swap Video
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5302 0.0500031C15.4602 0.0200031 15.3802 0.0100098 15.3102 0.0100098H2.62025C2.30025 0.0100098 2.04018 0.270012 2.04018 0.590012C2.04018 0.910012 2.30025 1.17 2.62025 1.17H13.9202L1.0602 14.03C0.830195 14.26 0.830195 14.62 1.0602 14.85C1.1702 14.96 1.32023 15.02 1.47023 15.02C1.62023 15.02 1.77014 14.96 1.88014 14.85L14.7402 1.99001V13.29C14.7402 13.61 15.0002 13.87 15.3202 13.87C15.6402 13.87 15.9002 13.61 15.9002 13.29V0.600006C15.9002 0.520006 15.8802 0.450005 15.8602 0.380005C15.8002 0.240005 15.6902 0.130007 15.5502 0.0700073L15.5302 0.0500031Z"
                      fill="#FF007A"
                    />
                  </svg>
                </h6>
              </a>
              <a href="https://fibswap.io/News.html">
                <h6>FibSWAP NEWS</h6>
              </a>
              <a href="https://fibswap.io/team.jpeg">
                <h6>FibSWAP Community Core Team</h6>
              </a>
             
            </div>
          </div>

          <div className="section">
            <h6 className="heading">Our Products</h6>
            <div className="list">

              <a href="https://dex.fibswap.io/">
                <h6>FibSwap DEx</h6>
              </a>

              <a href="https://fibpal.fibswap.io/">
                <h6>FibPal NFT Payments</h6>
              </a>

              <a href="https://fibswap.tech/">
                <h6>Fibswap Tech</h6>
              </a>

              <a href="https://fibpal.fibswap.io/explore">
                <h6>Fibswap Marketplace</h6>
              </a>

              <a href="https://fibswap.io/FiboAPP.html">
                <h6>FiboAPP</h6>
              </a>
             
              <a href="https://staking.fibswap.io/">
                <h6>$FIBO STAKING</h6>
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
