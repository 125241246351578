import React from "react";
import styled from "styled-components";
// import FibHeader from '../../components/FibHeader'
import FarmCard from "../../components/Cards/Farm";
import { usePools } from "state/hooks";
import Dividend from "components/Cards/Dividends";
import Footer from "components/Footer";
// import 'landing.css';

const HomeDiv = styled.div`
  h1 {
    text-align: center;
    color: white;
    margin-bottom: 2rem;
    font-weight: 300;
  }
  .col-lg-4 {
    padding: 0 4px;
  }
  .row {
    margin: 0;
  }
  padding-left: 40px;
  padding-right: 40px;
  // .container{
  //   margin:60px 0;
  //   .heading{
  //     text-align:center;
  //     margin-bottom:30px;
  //   }
  // }
  .cardsouter {
    display: flex;
    margin: 0 -8px;
    justify-conten: space-between;
    align-items: flex-start;
    flex-flow: wrap;
    .maincardheading {
      width: 100%;
      margin: 0 10px 14px;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
    .row {
      justify-content: center;
    }
    .col-lg-4 {
      margin-bottom: 10px;
    }
    .dividents {
      margin-left: 4px;
    }
  }
`;
const Landing: React.FC = () => {
  const pools = usePools();
  return (
    <HomeDiv>
      {/* <Header/> */}
      {/* <FibHeader/> */}
      <h1>$FIBO Token Staking</h1>
      <div className="cardsouter">
        <div className="row w-100">
          {pools.map((pool) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-6">
                <FarmCard poolName={pool.name} isLpPool={pool.isLpPool} />
              </div>
            );
          })}
        </div>

        <div className="dividents row w-100">
          <div className="col-md-6 col-lg-4">
            <Dividend
              poolName={"FIBO-1"}
              name={"First Dividend"}
              start={"Dec 1, 2022"}
              end={"Dec 12, 2022"}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <Dividend
              poolName={"FIBO-2"}
              name={"Second Dividend"}
              start={"Jan 1, 2023"}
              end={"Mar 31, 2023"}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <Dividend
              poolName={"FIBO-3"}
              name={"Third Dividend"}
              start={"Apr 1, 2023"}
              end={"Jun 30, 2023"}
            />
          </div>
        </div>
      </div>
      <Footer />
    </HomeDiv>
  );
};

export default Landing;
